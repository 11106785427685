$(function() {
    // Font-size controller
    var $text_smaller = $(".header__text-controls--small");
    var $text_normal = $(".header__text-controls--normal");
    var $text_larger = $(".header__text-controls--large");
    var $text_controller = $(".header__text-control");
    // var font_size = parseInt($('body').css('font-size'),10);

    $text_smaller.click(function(event){
        event.preventDefault();
        $("h1, h2, h3, h4, h5, h6").animate({"font-size":"-=1"});
        $("span").animate({"font-size":"-=1"});
        $("p").animate({"font-size":"-=1"});
        $(".header__phone-number").animate({"font-size":"-=1"});
        $(".footer__links li a").animate({"font-size":"-=1"});
        $("nav a").animate({"font-size":"-=1"});
        $(".info-block__title, .info-block__sub-title").animate({"font-size":"-=1"});
        $(".twitter__tweet").animate({"font-size":"-=1"});
    });

    $text_larger.click(function(event){
        event.preventDefault();
        $("h1, h2, h3, h4, h5, h6").animate({"font-size":"+=1"});
        $("p").animate({"font-size":"+=1"});
        $("span").animate({"font-size":"+=1"});
        $(".header__phone-number").animate({"font-size":"+=1"});
        $(".footer__links li a").animate({"font-size":"+=1"});
        $("nav a").animate({"font-size":"+=1"});
        $(".info-block__title, .info-block__sub-title").animate({"font-size":"+=1"});
        $(".twitter__tweet").animate({"font-size":"+=1"});
    });

    $text_controller.click(function() {
        $text_controller.removeClass("selected");
        $(this).addClass("selected");
    });

    // Swiper
    var mySwiper = new Swiper ('.swiper-container', {
        // Optional parameters
        // direction: 'vertical',
        loop: true,

        // If we need pagination
        pagination: '.swiper-pagination',

        // Navigation arrows
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',

        // And if we need scrollbar
        scrollbar: '.swiper-scrollbar',
    });
});
